@import "../../scss/functions";

.pageHeader {
    justify-content: space-between;
    align-items: center;
    background: url("../../assets/images/spacer.png");
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 30px;
    padding-left: 5px;
    position: relative;
    display: flex;
    width: 100%;
  
    h3 {
      font-weight: normal;
      flex-grow: 1;
      font-size: 30px;
      color: #d4d3cd;
      margin: 0;
  
  
    }
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      top: -30px;
      bottom: 0;
      content: "";
      z-index: -1;
      display: block;
      background: url("../../assets/images/glow.png");
      background-position: left bottom;
      background-size: contain;
      background-repeat: no-repeat;
  
    }
}
.transparentButton {
  display: inline-block;
  background-color: rgba(11, 17, 15, 0.2);
  background-image: url("../../assets/images/buttongradient.png");
  background-size: 110%;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  padding: 0 25px 0 50px;
  font-size: 18px;
  color: #a08336;
  font-style: italic;
  font-family: inherit;
  position: relative;
  border: 0;
  white-space: nowrap;
  cursor: pointer;
  &::before {
    position: absolute;
    left: 25px;
    top: 50%;
    margin-top: -6px;
    width: 13px;
    height: 12px;
    background-image: url("../../assets/images/back.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: "";
  }
}

.downloads {
  padding: 0 15px;
  @include desktop {
    padding: 0 50px;

  }

  p {
    color: #889690;
    font-size: 20px;
    text-align: center;
  }
  
}
.downloadsWrapper {
  padding: 0 15px;
}
.downloadCards {
  margin: 0;
  padding: 0;
  display: block;
  margin-top: 40px;
}



