@import "../../scss/functions";

.postTitle {
  color: #ffca3d;
  font-size: remify(22px);
  font-weight: normal;
  margin: 0;
  margin-bottom: 10px;
  font-family: Constantia, serif;
}
.postMeta {
  color: #627069;
  font-size: remify(16px);
  margin: 0;
  font-style: italic;
}
.postAuthor {
  color: #90a39a;
}
.post {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px 20px;
  @include desktop {
    padding: 30px 40px;
  }
  background-color: rgba(16, 26, 24, 0.7);
  position: relative;

  &::after {
    position: absolute;
    right: 0;
    top: 0;
    content: "";
    left: 0;
    bottom: 0;
    background-image: url("../../assets/images/post-glow.png");
    background-position: right top;
    background-repeat: no-repeat;
    z-index: -1;
  }

}
.postContent {
  color: #9daca5;
  font-family: serif;
  font-size: remify(18px);
  img {
    width: 100%;
    display: block;
  }

}
