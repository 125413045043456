@import "./scss/functions";
.pageWrapper {
  width: 100%;
}


.mainContent {
  @include desktop {
    display: flex;
  }
  
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  box-sizing: border-box;
  
  >*:first-child {
    flex-grow: 1;
  }
  >*:last-child {
    @include desktop {
      width: 400px;
    }
   
  }
}