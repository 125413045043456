@import "../../scss/functions";

$header-height: 100px;
.headerBar {
  height: remify($header-height);
  width: 100%;
  background-color: rgba(8, 13, 16, 0.949);
  position: relative;


  &::after {
    content: "";
    position: absolute;
    left:0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient( 90deg, rgb(44,200,72) 0%, rgba(44,200,72,0.5) 50%, rgb(44,200,72) 100%);
    opacity: 0.302;
    z-index: -1;
    display: block;
  }

}

.headerContainer {
  margin: 0 auto;
  height: 100%;
  align-items: center;
  max-width: remify(880px);
  display: flex;
  justify-content: center;

  //Position the 2 Menus
  > *:first-child {
    position: absolute;
    right: 50%;
    margin-right: remify(140px);
    display: none;
    @include desktop {
      display: flex;
    }
  }
  > *:last-child {
    position: absolute;
    left: 50%;
    margin-left: remify(140px);
    display: none;
    @include desktop {
      display: flex;
    }
  }
  
}
.logo {
  font-size: 0;
  width: remify(280px);
  background-image: url("../../assets/images/aros-logo.png");
  background-size: cover;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: remify(-140px);
  height: remify(194px);

}
//Donot Remify
.localization {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  
  flex-direction: row;
  align-items: center;
  padding: 0 (20px);
  display: none;
  @include desktop {
    display: flex;
  }

}
//Donot Remify
.selectWrapper {
  width: (70px);
}


.authentication {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: none;
  @include desktop {
    display: flex;
  }
  flex-direction: row;
  align-items: center;
  color: white;
  padding: 0 remify(20px);

}

.authenticationButton {
  background: #182c2a;
  padding: remify(12px) remify(25px);
  border: 0;
  color: #bfbeb8;
  font-family: Constantia;
  font-size: 15px;
  &.green {
    border-color: rgb(20, 24, 28);
    background-color: rgba(66, 255, 124, 0.5);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
    color: #eafff1;
  }


  
}
.authenticationButtonSeparator {
  margin: 0 remify(10px);
  color: #af853d;
}


