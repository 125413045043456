@import "vars";
@import "functions";
@font-face {
  font-family: Constantine;
  src: url("../assets/fonts/constantine/Constantine.ttf");
}
@font-face {
  font-family: Constantia;
  src: url("../assets/fonts/constantia/constan.ttf");
}
@font-face {
  font-family: "Acumin";
  src: url("../assets/fonts/acumin/Acumin\ Variable\ Concept.ttf");
}

body {
  margin: 0;
  font-family: Constantia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("../assets/images/Background.png")
}
h1,h2,h3,h4,h5,h6 {
  font-family: Constantine, serif;
}

code {
  font-family: source-code-pro, "Courier New", monospace;
}
:root {
  font-size: 16px;
  @include desktop {
    font-size: 13px;

  }
  @include desktop-large {
    font-size: 16px;
  }
}