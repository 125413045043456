@import "../../scss/functions";
.sidebar {
  color: white;
}
.count {
  text-align: center;

  background: url("../../assets/images/glow-count.png");

  
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 10px;
  padding-top: 40px;
  padding-bottom: 10px;
  @include desktop {
    margin-top: -40px;
  }

}
.countNumber {
  font-size: 55px;
  text-align: center;
  margin: 0;
  color: #ffca3d;
}
.countLabel {
  font-size: 20px;
  color: #c9e4ce;
  font-family: Constantine, serif;
}
.worldMap {
  width: 100%;
  height: 220px;
  background: url("../../assets/images/scroll.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.worldMapLink {
  color: rgb(254, 249, 237);
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.33);
  font-size: 40px;
  padding: 40px;
  text-decoration: none;
  font-family: Constantine, serif;

}
.worldMapLinkLabel {
  color: #274132;
  font-size: 12px;
  text-align: center;

  margin: 0;
}
.social {
  margin: 50px 0;
}
.discordWrapper {
  margin-top: 50px;
  padding: 0 10px;
}
.socialLinks {
  display: flex;
  margin: 0;
  justify-content: center;
  padding: 0;
}
.socialLabel {
  color: #d4d3cd;
  text-align: center;
  font-size: 20px;

}
.socialItem {
  display: block;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }

}
.socialLink {
  font-size: 0;
  display: block;
  width: 42px;
  height: 42px;
  background-size: cover;
  background-repeat: no-repeat;
}

.socialLinkFacebook {
  background-image: url("../../assets/images/facebook.png");
}
.socialLinkYoutube {
  background-image: url("../../assets/images/youtube.png");

}
.socialLinkTwitch {
  background-image: url("../../assets/images/twitch.png");

}
.socialLinkTwitter {
  background-image: url("../../assets/images/twitter.png");

}