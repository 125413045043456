@import "../../scss/functions";

.news {
  color: white;
  margin-top: 50px;
  @include desktop {
    margin-top: 20px;
  }
  
}

.newsHeader {
  justify-content: space-between;
  align-items: center;
  background: url("../../assets/images/spacer.png");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 20px;
  padding-left: 5px;
  position: relative;
  display: flex;
  width: 100%;

  h3 {
    font-weight: normal;
    flex-grow: 1;
    font-size: remify(30px);
    color: #d4d3cd;
    margin: 0;


  }
  &::after {
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    bottom: 0;
    content: "";
    z-index: -1;
    display: block;
    background: url("../../assets/images/glow.png");
    background-position: left bottom;
    background-size: contain;
    background-repeat: no-repeat;

  }
}



.newsContainer {
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  @include desktop {
    padding: 0 15px;
  }

}


.transparentButton {
  display: inline-block;
  background-color: rgba(11, 17, 15, 0.2);
  background-image: url("../../assets/images/buttongradient.png");
  background-size: 101%;
  background-position: center;
  background-repeat: no-repeat;
  height: 45px;
  padding: 0 25px 0 40px;
  font-size: 18px;
  color: #a08336;
  font-style: italic;
  font-family: inherit;
  position: relative;
  border: 0;
  white-space: nowrap;
  cursor: pointer;
  &::before {
    position: absolute;
    left: 25px;
    top: 50%;
    margin-top: -6px;
    width: 6px;
    height: 12px;
    background-image: url("../../assets/images/caret.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    content: "";
  }
}


.bottomActions {
  text-align: right;
  margin-top: 30px;
}
.newsContainer {
  
}