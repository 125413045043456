@import "../../scss/functions";

.banner {
  background-size: cover;
  height: (400px);
  width: 100%;
  color: #f2fff6;
  position: relative;
  margin-bottom: (40px);
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    height: (400px);
    width: 100%;
    content: "";
    display: block;

    z-index: -1;
    background: url("../../assets/images/gradient.png");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
  }
  &.bannerMini {
    height: (300px);
    &::after {
      height: (300px);

    }
    .bannerAction {
      display: none;
    }
    .bannerText {
      display: none;
    }
    .bannerWrapper {
      height: 300px;
    }
    .bannerContent {
      height: (300px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include desktop {
        width: 100%;
        text-align: center;
      }

    }
    .character {
      @include desktop {
        display: none;
      }
    }

  }
}
.bannerWrapper {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @include desktop {
    align-items: stretch;
    text-align: left;
    justify-content: space-between;;
  }
  height: (400px);
  overflow: visible;

  
}
.character {
  display: none;
  @include desktop {
    display: block;
  }
  height: (440px);
  width: auto;
  margin-top: -20px;
  z-index: 2;
  position: relative;
}
.bannerHeading {
  padding-top: 20px;
  font-size: (40px);
  font-weight: normal;
  margin: 0 0 (20px) 0;
}

.bannerContent {
  padding: 15px;
  @include desktop {
    width: 52%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }


}
.bannerText {
  color: #92a898;
  margin: 0;
  font-size: (20px);
  margin: 0 0 (20px) 0;
}
.bannerAction {
  @include desktop {
    text-align: right;
  }
  
}

.transparentButton {
  display: inline-block;
  background-color: rgba(14, 24, 25, 0.3);
  background-image: url("../../assets/images/buttongradient.png");
  background-size: 101%;
  background-position: center;
  background-repeat: no-repeat;
  height: remify(45px);
  padding: 0 remify(25px);
  font-size: remify(18px);
  color: #a08336;
  font-style: italic;
  font-family: inherit;
  border: 0;
  margin-bottom: (20px);
  cursor: pointer;
}

.playStoreButton {
  display: inline-block;
  height: remify(65px);
  margin-left: 10px;
  cursor: pointer;
}

.bannerDownloadButtons {
  margin-top: 15px;
  text-align: center;
}