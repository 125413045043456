@import "../../scss/functions";
.headerMenu {
  display: flex;
  padding: 0;
  margin: 0;
  padding: 0 remify(10px);

}
.headerMenuItem {

  color: #d4d3cd;
  font-size: remify(20px);
  margin: 0;
  padding: 0;
  display: block;
  font-weight: normal;
  margin-right: remify(70px);
  position: relative;
  &::after {
    background-color: rgb(0, 0, 0);
    opacity: 0.2;
    position: absolute;
    width: 2px;
    height: remify(44px);
    z-index: 15;
    right: 0;
    top: 50%;
    margin-top: remify(-22px);
    margin-right: remify(-38px);
    display: block;
    content: "";
  }

  &:last-child {
    margin-right: 0;
    &::after {
      display: none;
    }
  }
  a {
    color: inherit;
    text-decoration: none;

  }
}