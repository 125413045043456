.bookmarks {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}


.bookmarkItemLink {
    margin: 0;
    padding: 0;

    color: white;
    font-family: "Cambria";
    color: #9daca5;
    text-decoration: none;
    font-size: 18px;
      

    
}
.bookmarkItem {
    margin: 0;
    padding: 0;
    display: block;
    margin-right: 60px;
    position: relative;
    
    &.active .bookmarkItemLink {
        color: #48f482;
    }

    &:after {
        content: ">";
        display: inline-block;
        position: absolute;
        top: 2px;
        right: -33px;
        color: rgb(157, 172, 165);
        text-decoration: none;
        font-size: 0;
        width: 9px;
        height: 18px;
        background-image: url("../../assets/images/separator.png");
        background-size: contain;

    }
    &:last-child {
        .bookmarkItemLink {
            color: #48f482;
        }
    
        margin-right: 0;
        &:after {
            display: none

        }

    }
}