@import "../../scss/functions";

.downloadCard {
  margin: 0;
  padding: 0;
  display: block;

  .cardContentWrapper {
    @include desktop {
      display: flex;

    }
  }
  
  .downloadCardContent {
    flex-grow: 1;
  }
  img {
    width: 75px;
    height: auto;
    margin-right: 30px;
  }
  h4 {
    color: #54dd78;
    margin: 0;
    margin-top: 10px;
    font-size: 18px;
    margin-bottom: 7px;
    a {
        text-decoration: none;
        color: inherit;
        &:hover {
            text-decoration: underline;
        }

    }
  }
  p {
    color: #6e7f77;
    text-align: left;
    font-size: 16px;
    margin: 0;
  }
}
.cardFooter {
    margin-top: 5px;
  p{
    text-align: right;
    font-size: 15px;
    color: #42594b;
  }
}
