@import "../../scss/functions";


.footer {
  background-color: rgba(0, 0, 0,0.4);
  color: #505a55;
  margin-top: 50px;

}
.footerContainer {
  @include desktop {
    display: flex;
    width: 1024px;

  }
  
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 60px 15px;
}
.footerLogoLabel {
  font-family: "Acumin";
  font-weight: 400;


}
.footerLogo {
  @include desktop {
    width: 400px;

  }
  
  text-align: center;

}
.footerNav {
  @include desktop {
    display: flex;
  }
  flex-grow: 1;

}
.footerNavList {
  display: block;
  margin-top: 50px;
  @include desktop {
    margin: 0 0 0 80px;
  }
  
  padding:0;
  li {
    display: block;
    margin: 0;
    padding:0;
    text-align: center;

    @include desktop {
      text-align: left;
  
    }
  }
  a {
    display: block;
    margin: 0;
    padding:0;
    font-size: 16px;
    line-height: 1.7;
    color: #505a55;
    text-decoration: none;
  }
  &:first-child {
    @include desktop {
      margin: 0 0 0 0;
    }
  }
}
.footerLogoHeading {
  display: inline-block;
  @include desktop {
    display: block;

  }
  width: 180px;
  height: 60px;
  background: url("../../assets/images/bitgatelogo.png");
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 0;
}
.copyright {
  height: 80px;
  background: #050808;
  text-align: center;
  color: #303c39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  

}