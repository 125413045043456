@import "../../scss/functions";

.highscores {
  p {
    color: #889690;
    font-size: 20px;
    text-align: center;
  }
  color: #c0beba;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  
}
.table {
    width: 100%;
    border-collapse: collapse;
    color: white;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    th,td {
        text-align: left;
    
        padding: 10px 0;
        
    }
    th {
        color: #425e50;
        padding-bottom: 20px;
        border-bottom: 2px solid rgba(10, 15, 14, 0.6);
          
    }
}
.filterLabel {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-family: "Constantine";
  color: rgb(163, 176, 170);
  text-transform: uppercase;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
}
.filter {
  padding-bottom: 10px;
  width: 100%;
  padding: 20px 10px;
  
  @include desktop {
    width: 50%;
    margin: 0;
    padding: 20px 10px;
  }
}
.filters {
  @include desktop {
    display: flex;
  }
  
}
.filterInput {
  display: block;
  height: 38px;
  width: 100%;
  box-sizing: border-box;
  background: #0f1613;
  border: 0;
  color: #bfbeb8;
  font-size: 16px;
  padding: 10px 20px;
  &::placeholder {
    color: #45534d;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
}